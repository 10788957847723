import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  ContentPhoto,
  FullPhoto,
  Hero,
  Layout,
  Navigation,
  TextBox,
  WhatWeDid,
} from "components/CaseLayout";
import { MERCEDES } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Mercedes = () => {
  const data = useCaseData(MERCEDES);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo}/>
      <Hero data={data.hero}/>
      <Navigation color="#0B0B0B" type={MERCEDES}/>
      <WhatWeDid color={data.color} data={data.what_we_did}/>
      <FullPhoto color={data.color} data={data.full_images.first}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.first}/>
      <ContentPhoto color={data.color} data={data.content_images.first}/>
      <FullPhoto data={data.full_images.second}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.second}/>
      <FullPhoto color={data.color} data={data.full_images.third}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.third}/>
      <FullPhoto color={data.color} data={data.full_images.fourth}/>
      <ContactForm/>
    </Layout>
  );
};

export default Mercedes;
